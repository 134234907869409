<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        A
        <number-value :value="massMetal" unit="\text{g}" />
        sample of an unknown metal at
        <number-value :value="tempMetal" unit="^\circ\text{C}" />
        was dropped in a bucket containing
        <number-value :value="massWater" unit="\text{g}" />
        of water initially at
        <number-value :value="tempWater" unit="^\circ\text{C.}" />
        The final temperature of the mixture was
        <number-value :value="tempFinal" unit="^\circ\text{C.}" />
        What is the specific heat capacity of the unknown metal (you may assume that the specific
        heat capacity of liquid water =
        <stemble-latex content="$4.184\,\text{J g}^{-1}\text{C}^{-1}$" />
        and that no water evaporates upon the addition of the metal).
      </p>

      <calculation-input
        v-model="inputs.heatCapacity"
        prepend-text="$\text{Heat Capacity:}$"
        append-text="$\text{J g}^{-1}\text{C}^{-1}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question392',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        heatCapacity: null,
      },
    };
  },
  computed: {
    massMetal() {
      return this.taskState.getValueBySymbol('massMetal').content;
    },
    massWater() {
      return this.taskState.getValueBySymbol('massWater').content;
    },
    tempMetal() {
      return this.taskState.getValueBySymbol('tempMetal').content;
    },
    tempWater() {
      return this.taskState.getValueBySymbol('tempWater').content;
    },
    tempFinal() {
      return this.taskState.getValueBySymbol('tempFinal').content;
    },
  },
};
</script>
